import React from 'react'
import logo from "../assets/MaluhiaHospiceLogo.png"
import { Mail, Phone, MapPin } from 'lucide-react';

const FooterSection = () => {
  return (
    <footer className="bg-white">
      <div className="container px-6 mx-auto pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div>
            <div className="flex justify-center text-teal-600 sm:justify-start">
              <a
                href="https://maluhiahospice.com"
                className="flex items-center space-x-3 rtl:space-x-reverse"
              >
                <img src={logo} className="h-16" alt="Maluhia Hospice Logo" />
              </a>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">About Us</p>
              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="text-gray-700 transition hover:text-maluhia-blue"
                    href="about"
                  >
                    About
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">Our Services</p>
              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="text-gray-700 transition hover:text-maluhia-blue"
                    href="#services"
                  >
                    End-of-Life Care
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-700 transition hover:text-maluhia-blue"
                    href="#services"
                  >
                    Family Support
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-700 transition hover:text-maluhia-blue"
                    href="#services"
                  >
                    Team Collaboration
                  </a>
                </li>
                <li>
                  <a
                    className="text-gray-700 transition hover:text-maluhia-blue"
                    href="#services"
                  >
                    Grief Support
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">Helpful Links</p>
              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="text-gray-700 transition hover:text-maluhia-blue"
                    href="faqs"
                  >
                    FAQs
                  </a>
                </li>
              </ul>
            </div>

            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">Contact Us</p>
              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                    href="mailto:contact@maluhiahospice.com"
                  >
                    <Mail className="w-5 h-5 text-gray-900" />
                    <span className="flex-1 text-gray-700">contact@maluhiahospice.com</span>
                  </a>
                </li>

                <li>
                  <a
                    className="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                    href="tel:(956)-620-2273"
                  >
                    <Phone className="w-5 h-5 text-gray-900" />
                    <span className="flex-1 text-gray-700">(956)-620-2273</span>
                  </a>
                </li>

                <li className="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end">
                  <MapPin className="w-5 h-5 text-gray-900" />
                  <address className="-mt-0.5 flex-1 not-italic text-gray-700">
                    1620 N Ed Carey Dr, Harlingen, TX 78550
                  </address>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-12 border-t border-gray-100 pt-6">
          <div className="text-center sm:flex sm:justify-between sm:text-left">
            <p className="text-sm text-gray-500">
              <span className="block sm:inline">All rights reserved.</span>

              <a
                className="inline-block text-teal-600 underline transition hover:text-teal-600/75"
                href="/terms"
              >
                Terms & Conditions
              </a>

              <span>&middot;</span>

              <a
                className="inline-block text-teal-600 underline transition hover:text-teal-600/75"
                href="/privacy"
              >
                Privacy Policy
              </a>
            </p>

            <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">
              &copy; 2024 Maluhia Hospice Care Pllc - Website Design By:{' '}
              <a 
                className="text-maluhia-blue hover:text-maluhia-blue/80" 
                href="https://ouitrend.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                OuiTrend Marketing Agency
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
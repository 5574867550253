import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, Search } from 'lucide-react';
import faqData from "../data/faqData.json";

const Faqs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);

  // Filter FAQs based on search term
  const filteredFaqs = faqData.faqs.filter(faq => 
    faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Split filtered FAQs into columns
  const midpoint = Math.ceil(filteredFaqs.length / 2);
  const leftColumnFaqs = filteredFaqs.slice(0, midpoint);
  const rightColumnFaqs = filteredFaqs.slice(midpoint);

  const FaqItem = ({ faq, index }) => {
    const isExpanded = expandedIndex === index;
    
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4, delay: index * 0.1 }}
        className="mb-6"
      >
        <button
          onClick={() => setExpandedIndex(isExpanded ? null : index)}
          className="flex items-center justify-between w-full px-4 py-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
        >
          <h3 className="text-lg font-medium text-gray-900 text-left pr-4">
            {faq.question}
          </h3>
          <motion.div
            animate={{ rotate: isExpanded ? 180 : 0 }}
            transition={{ duration: 0.3 }}
          >
            <ChevronDown className="w-5 h-5 text-gray-500" />
          </motion.div>
        </button>
        
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="overflow-hidden"
            >
              <div className="px-4 py-3 text-gray-600 leading-relaxed">
                {faq.answer}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50 pt-20">
      <section className="max-w-screen-xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-2xl mx-auto mb-12"
        >
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            {faqData.title}
          </h1>
          <p className="text-lg text-gray-600 mb-8">
            {faqData.description}
          </p>
          
          {/* Search Bar */}
          <div className="relative max-w-md mx-auto">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            <input
              type="text"
              placeholder="Search questions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-3 rounded-full border border-gray-200 focus:border-maluhia-blue focus:ring-2 focus:ring-maluhia-blue/20 focus:outline-none transition-colors"
            />
          </div>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-4">
            {leftColumnFaqs.map((faq, index) => (
              <FaqItem key={index} faq={faq} index={index} />
            ))}
          </div>
          <div className="space-y-4">
            {rightColumnFaqs.map((faq, index) => (
              <FaqItem 
                key={index + midpoint} 
                faq={faq} 
                index={index + midpoint} 
              />
            ))}
          </div>
        </div>

        {filteredFaqs.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-12"
          >
            <p className="text-gray-600">
              No questions found matching your search. Try different keywords.
            </p>
          </motion.div>
        )}
      </section>
    </div>
  );
};

export default Faqs;
import React, { createContext, useState, useEffect, useContext } from 'react';

// Create the authentication context
export const AuthContext = createContext();

// Hook for using the auth context
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check if user is logged in from localStorage
    const checkLoggedIn = () => {
      const user = localStorage.getItem('user');
      const userRole = localStorage.getItem('userRole');
      
      if (user) {
        setCurrentUser(JSON.parse(user));
        setIsAdmin(userRole === 'admin');
      }
      
      setIsLoading(false);
    };
    
    checkLoggedIn();
  }, []);

  // Login function - in production, this would connect to a backend API
  const login = async (email, password) => {
    // This is a mock implementation - replace with actual API call
    try {
      // Simulate API request
      setIsLoading(true);
      
      // Mock login - replace with actual login logic
      if (email === 'admin@maluhia.org' && password === 'password') {
        const user = { id: '1', name: 'Admin User', email: 'admin@maluhia.org' };
        setCurrentUser(user);
        setIsAdmin(true);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userRole', 'admin');
        return { success: true, user };
      } else if (email === 'employee@maluhia.org' && password === 'password') {
        const user = { id: '2', name: 'Employee User', email: 'employee@maluhia.org' };
        setCurrentUser(user);
        setIsAdmin(false);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userRole', 'employee');
        return { success: true, user };
      } else {
        return { success: false, message: 'Invalid credentials' };
      }
    } catch (error) {
      return { success: false, message: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  // Logout function
  const logout = () => {
    setCurrentUser(null);
    setIsAdmin(false);
    localStorage.removeItem('user');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');
  };

  const value = {
    currentUser,
    isLoading,
    isAdmin,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
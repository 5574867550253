import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Phone, Mail, MapPin, Check, AlertCircle } from 'lucide-react';
import emailjs from "@emailjs/browser";

const Contact = () => {
  emailjs.init("Kdod9F42WL2j3AIlw");

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    consent: false
  });

  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid phone number";
    }

    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }

    if (!formData.consent) {
      newErrors.consent = "Please agree to receive communications";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmitStatus({ type: "info", message: "Sending your message..." });

      const templateParams = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.message,
        consent: formData.consent ? "Yes, consented to communications" : "No consent provided",
      };

      try {
        await emailjs.send(
          "service_tl22u13",
          "template_2d8oyv8",
          templateParams,
          {
            publicKey: "Kdod9F42WL2j3AIlw",
          }
        );

        setSubmitStatus({
          type: "success",
          message: "Thank you for contacting us! We will get back to you soon.",
        });

        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          consent: false,
        });
      } catch (error) {
        console.error("EmailJS Error:", error);
        setSubmitStatus({
          type: "error",
          message: "There was an error sending your message. Please try again later.",
        });
      }
    }
  };

  return (
    <div className="min-h-screen pt-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto text-center"
        >
          <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl mb-6">
            Contact Us
          </h1>
          <p className="text-lg text-gray-600">
            We're here to help. Reach out to us for any questions about our hospice care services.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5 mt-12">
          <div className="lg:col-span-2 lg:py-12">
            <div className="space-y-8">
              <div className="flex items-center gap-4">
                <div className="flex-shrink-0">
                  <span className="p-3 rounded-full bg-maluhia-blue/10 text-maluhia-blue">
                    <Phone className="w-5 h-5" />
                  </span>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Phone</h3>
                  <p className="mt-1 text-gray-600">(956)-620-2273</p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex-shrink-0">
                  <span className="p-3 rounded-full bg-maluhia-blue/10 text-maluhia-blue">
                    <Mail className="w-5 h-5" />
                  </span>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Email</h3>
                  <p className="mt-1 text-gray-600">contact@maluhiahospice.com</p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                <div className="flex-shrink-0">
                  <span className="p-3 rounded-full bg-maluhia-blue/10 text-maluhia-blue">
                    <MapPin className="w-5 h-5" />
                  </span>
                </div>
                <div>
                  <h3 className="font-medium text-gray-900">Office</h3>
                  <p className="mt-1 text-gray-600">1620 N Ed Carey Dr, Harlingen, TX 78550</p>
                </div>
              </div>
            </div>
          </div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-10"
          >
            {submitStatus.message && (
              <div
                className={`mb-6 p-4 rounded-lg ${
                  submitStatus.type === "success"
                    ? "bg-green-50 text-green-700"
                    : submitStatus.type === "error"
                    ? "bg-red-50 text-red-700"
                    : "bg-blue-50 text-blue-700"
                }`}
              >
                <p className="text-sm">{submitStatus.message}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="text-sm font-medium text-gray-700" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className={`w-full rounded-lg border p-3 text-sm focus:ring-maluhia-blue focus:border-maluhia-blue ${
                    errors.name ? "border-red-500" : "border-gray-200"
                  }`}
                  placeholder="Your name"
                />
                {errors.name && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.name}
                  </p>
                )}
              </div>

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="text-sm font-medium text-gray-700" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`w-full rounded-lg border p-3 text-sm focus:ring-maluhia-blue focus:border-maluhia-blue ${
                      errors.email ? "border-red-500" : "border-gray-200"
                    }`}
                    placeholder="Your email"
                  />
                  {errors.email && (
                    <p className="mt-1 text-sm text-red-500 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.email}
                    </p>
                  )}
                </div>

                <div>
                  <label className="text-sm font-medium text-gray-700" htmlFor="phone">
                    Phone
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={`w-full rounded-lg border p-3 text-sm focus:ring-maluhia-blue focus:border-maluhia-blue ${
                      errors.phone ? "border-red-500" : "border-gray-200"
                    }`}
                    placeholder="Your phone"
                  />
                  {errors.phone && (
                    <p className="mt-1 text-sm text-red-500 flex items-center">
                      <AlertCircle className="w-4 h-4 mr-1" />
                      {errors.phone}
                    </p>
                  )}
                </div>
              </div>

              <div>
                <label className="text-sm font-medium text-gray-700" htmlFor="message">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className={`w-full rounded-lg border p-3 text-sm focus:ring-maluhia-blue focus:border-maluhia-blue ${
                    errors.message ? "border-red-500" : "border-gray-200"
                  }`}
                  placeholder="Your message"
                  rows="6"
                />
                {errors.message && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.message}
                  </p>
                )}
              </div>

              <div className="mt-4">
                <label className="flex gap-4">
                  <input
                    type="checkbox"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                    className={`h-5 w-5 rounded border-gray-300 text-maluhia-blue focus:ring-maluhia-blue ${
                      errors.consent ? "border-red-500" : ""
                    }`}
                  />
                  <span className="text-sm text-gray-600">
                    I agree to receive communications via phone call, SMS, and email. Message and data rates may apply. Message frequency varies.
                  </span>
                </label>
                {errors.consent && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.consent}
                  </p>
                )}
              </div>

              <button
                type="submit"
                className="inline-flex w-full items-center justify-center rounded-lg bg-maluhia-blue px-5 py-3 text-white transition hover:bg-maluhia-blue/90 focus:outline-none focus:ring focus:ring-maluhia-blue/20"
              >
                <span className="font-medium">Send Message</span>
                <Check className="ml-2 h-5 w-5" />
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
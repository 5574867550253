import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import PortalNavbar from './PortalNavbar';
import FooterSection from '../sections/footer-section';

const EmployeeDashboard = () => {
  const { currentUser } = useAuth();
  const [clockedIn, setClockedIn] = useState(false);
  const [clockInTime, setClockInTime] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [recentShifts, setRecentShifts] = useState([]);
  
  // Check if user is clocked in from local storage
  useEffect(() => {
    const storedClockInStatus = localStorage.getItem('clockedIn');
    const storedClockInTime = localStorage.getItem('clockInTime');
    
    if (storedClockInStatus === 'true' && storedClockInTime) {
      setClockedIn(true);
      setClockInTime(new Date(storedClockInTime));
    }
    
    // Mock data for recent shifts
    const mockShifts = [
      { date: '2025-03-05', clockIn: '08:00 AM', clockOut: '04:30 PM', duration: '8h 30m', location: '1234 Hospice Way, Honolulu, HI' },
      { date: '2025-03-04', clockIn: '08:15 AM', clockOut: '05:00 PM', duration: '8h 45m', location: '1234 Hospice Way, Honolulu, HI' },
      { date: '2025-03-03', clockIn: '07:55 AM', clockOut: '04:00 PM', duration: '8h 05m', location: '5678 Care Street, Honolulu, HI' },
    ];
    setRecentShifts(mockShifts);
  }, []);
  
  // Function to get current location
  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  };
  
  // Function to get address from coordinates (mock implementation)
  const getAddressFromCoordinates = async (coordinates) => {
    // In a real app, you would use a geocoding service like Google Maps or Mapbox
    // This is just a mock
    return '1234 Hospice Way, Honolulu, HI';
  };
  
  // Handle clock in
  const handleClockIn = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      // Get current location
      const location = await getCurrentLocation();
      const address = await getAddressFromCoordinates(location);
      
      setCurrentLocation(address);
      setClockedIn(true);
      const now = new Date();
      setClockInTime(now);
      
      // Save to localStorage (in a real app, this would go to a backend)
      localStorage.setItem('clockedIn', 'true');
      localStorage.setItem('clockInTime', now.toISOString());
      localStorage.setItem('clockInLocation', address);
      
    } catch (err) {
      setError('Failed to clock in: ' + (err.message || 'Unknown error'));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Handle clock out
  const handleClockOut = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      // Get current location
      const location = await getCurrentLocation();
      const address = await getAddressFromCoordinates(location);
      
      setClockedIn(false);
      
      // Calculate duration
      const clockOutTime = new Date();
      const durationMs = clockOutTime - clockInTime;
      const hours = Math.floor(durationMs / (1000 * 60 * 60));
      const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
      const duration = `${hours}h ${minutes}m`;
      
      // Mock saving the shift data
      const newShift = {
        date: clockOutTime.toISOString().split('T')[0],
        clockIn: clockInTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        clockOut: clockOutTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
        duration,
        location: localStorage.getItem('clockInLocation'),
        clockOutLocation: address,
      };
      
      // Update recent shifts (prepend)
      setRecentShifts([newShift, ...recentShifts]);
      
      // Clear localStorage (in a real app, this would go to a backend)
      localStorage.removeItem('clockedIn');
      localStorage.removeItem('clockInTime');
      localStorage.removeItem('clockInLocation');
      
    } catch (err) {
      setError('Failed to clock out: ' + (err.message || 'Unknown error'));
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Calculate time elapsed since clock in
  const getElapsedTime = () => {
    if (!clockInTime) return '';
    
    const now = new Date();
    const elapsedMs = now - clockInTime;
    const hours = Math.floor(elapsedMs / (1000 * 60 * 60));
    const minutes = Math.floor((elapsedMs % (1000 * 60 * 60)) / (1000 * 60));
    
    return `${hours}h ${minutes}m`;
  };
  
  return (
    <>
    
      <div className="min-h-screen bg-gray-100">
        <div className="py-10">
          <header>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">Employee Dashboard</h1>
            </div>
          </header>
          <main>
            <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
              {/* Welcome Section */}
              <div className="px-4 py-8 sm:px-0">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 className="text-lg leading-6 font-medium text-gray-900">
                      Welcome back, {currentUser?.name || 'Employee'}
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Today is {new Date().toLocaleDateString()}
                    </p>
                  </div>
                </div>
              </div>
              
              {/* Clock In/Out Section */}
              <div className="px-4 py-8 sm:px-0">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Time Tracking
                    </h3>
                    {error && (
                      <div className="mt-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        <span className="block sm:inline">{error}</span>
                      </div>
                    )}
                    <div className="mt-6 flex flex-col sm:flex-row sm:items-center justify-between">
                      <div>
                        {clockedIn ? (
                          <div>
                            <p className="text-sm font-medium text-gray-500">Clocked in at</p>
                            <p className="text-lg font-semibold">
                              {clockInTime?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </p>
                            <p className="text-sm text-gray-500 mt-1">
                              Elapsed time: {getElapsedTime()}
                            </p>
                            <p className="text-sm text-gray-500 mt-1">
                              Location: {localStorage.getItem('clockInLocation')}
                            </p>
                          </div>
                        ) : (
                          <p className="text-lg font-medium text-gray-500">You are not clocked in</p>
                        )}
                      </div>
                      
                      <div className="mt-4 sm:mt-0">
                        {clockedIn ? (
                          <button
                            onClick={handleClockOut}
                            disabled={isLoading}
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            {isLoading ? 'Processing...' : 'Clock Out'}
                          </button>
                        ) : (
                          <button
                            onClick={handleClockIn}
                            disabled={isLoading}
                            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {isLoading ? 'Processing...' : 'Clock In'}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Recent Shifts Section */}
              <div className="px-4 py-8 sm:px-0">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Recent Shifts
                    </h3>
                    <Link
                      to="/portal/timesheet"
                      className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all
                    </Link>
                  </div>
                  <div className="border-t border-gray-200">
                    <div className="overflow-x-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Date
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Clock In
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Clock Out
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Duration
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Location
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {recentShifts.map((shift, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {shift.date}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {shift.clockIn}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {shift.clockOut}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {shift.duration}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {shift.location}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <FooterSection />
    </>
  );
};

export default EmployeeDashboard;
import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import PortalNavbar from './PortalNavbar';
import Footer from '../sections/footer-section';

const PortalLayout = ({ requireAdmin = false }) => {
  const { currentUser, isLoading } = useAuth();
  const location = useLocation();
  
  // While checking authentication status, show loading
  if (isLoading) {
    return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
  }
  
  // If not authenticated, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }
  
  // If admin area but user is not admin, redirect to employee dashboard
  if (requireAdmin && currentUser.role !== 'admin') {
    return <Navigate to="/portal/dashboard" replace />;
  }
  
  return (
    <div className="flex flex-col min-h-screen">
      <PortalNavbar isAdmin={requireAdmin} />
      <main className="flex-grow bg-gray-100">
        <Outlet />
      </main>

    </div>
  );
};

export default PortalLayout;
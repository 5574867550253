import React from 'react';
import { motion } from 'framer-motion';
import { Heart, Users, Clock, Shield } from 'lucide-react';

const About = () => {
  const values = [
    {
      icon: <Heart className="w-6 h-6 text-maluhia-blue" />,
      title: "Compassion",
      description: "We approach every patient and family with deep empathy and understanding, ensuring comfort and dignity throughout their journey."
    },
    {
      icon: <Users className="w-6 h-6 text-maluhia-blue" />,
      title: "Family-Centered",
      description: "We recognize that hospice care affects the entire family, and we provide comprehensive support for both patients and their loved ones."
    },
    {
      icon: <Clock className="w-6 h-6 text-maluhia-blue" />,
      title: "24/7 Availability",
      description: "Our dedicated team is available around the clock to provide care and support whenever our patients and families need us."
    },
    {
      icon: <Shield className="w-6 h-6 text-maluhia-blue" />,
      title: "Excellence",
      description: "We maintain the highest standards of medical care while focusing on comfort and quality of life for our patients."
    }
  ];

  return (
    <div className="pt-16">
      {/* Hero Section */}
      <section className="bg-gray-50">
        <div className="max-w-screen-xl px-4 py-24 mx-auto">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="max-w-3xl mx-auto text-center"
          >
            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
              About Maluhia Hospice
            </h1>
            <p className="mt-6 text-lg leading-relaxed text-gray-700">
              Dedicated to providing compassionate end-of-life care and support for our community in Harlingen and surrounding areas. We believe in making every moment count and ensuring dignity and comfort for our patients and their families.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Our Values Section */}
      <section className="py-24">
        <div className="max-w-screen-xl px-4 mx-auto">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">Our Core Values</h2>
            <p className="mt-4 text-gray-600">
              These principles guide every aspect of our care and service delivery.
            </p>
          </div>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
            {values.map((value, index) => (
              <motion.div
                key={value.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
                className="p-6 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow"
              >
                <div className="w-12 h-12 mb-4 rounded-lg bg-maluhia-blue/10 flex items-center justify-center">
                  {value.icon}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {value.title}
                </h3>
                <p className="text-gray-600">
                  {value.description}
                </p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-white py-24">
        <div className="max-w-screen-xl px-4 mx-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                Our Mission
              </h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                At Maluhia Hospice, our mission is to provide the highest quality of care, respect, compassion, and support to all patients and families during their end-of-life journey. We strive to ensure they experience peace, tranquility, and serenity during this important time.
              </p>
              <p className="text-gray-600 leading-relaxed">
                We believe in making every moment count and work tirelessly to enhance the quality of life for our patients while providing essential support to their loved ones. Our comprehensive approach addresses physical, emotional, and spiritual needs.
              </p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              className="space-y-6"
            >
              <div className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold text-gray-900 mb-3">
                  Why Choose Us?
                </h3>
                <ul className="space-y-3 text-gray-600">
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 bg-maluhia-blue rounded-full"></span>
                    Expert medical care focused on comfort and quality of life
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 bg-maluhia-blue rounded-full"></span>
                    Comprehensive emotional and spiritual support
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 bg-maluhia-blue rounded-full"></span>
                    Family education and support services
                  </li>
                  <li className="flex items-center gap-2">
                    <span className="w-1.5 h-1.5 bg-maluhia-blue rounded-full"></span>
                    24/7 availability for patient and family needs
                  </li>
                </ul>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-maluhia-blue/5 py-16">
        <div className="max-w-screen-xl px-4 mx-auto text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-6">
            Ready to Learn More?
          </h2>
          <div className="flex flex-wrap justify-center gap-4">
            <a
              href="/contact"
              className="inline-flex items-center px-8 py-3 text-sm font-medium text-white bg-maluhia-blue rounded-full hover:bg-maluhia-blue/90 transition-colors"
            >
              Contact Us
            </a>
            <a
              href="/volunteer"
              className="inline-flex items-center px-8 py-3 text-sm font-medium text-maluhia-blue border border-maluhia-blue rounded-full hover:bg-maluhia-blue/10 transition-colors"
            >
              Volunteer Opportunities
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { groupShiftsByDate, calculateWeeklyHours, getCurrentWeekDates } from '../utils/timeUtils';

const Timesheet = () => {
  const { currentUser } = useAuth();
  const [shifts, setShifts] = useState([]);
  const [groupedShifts, setGroupedShifts] = useState({});
  const [weeklyHours, setWeeklyHours] = useState(null);
  const [dateRange, setDateRange] = useState(() => getCurrentWeekDates());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadShifts = () => {
      setIsLoading(true);

      // In a real app, this would fetch from an API or database
      // For now, we'll use localStorage for demo purposes
      const storedShifts = localStorage.getItem('shiftHistory');
      let shiftsData = [];

      if (storedShifts) {
        try {
          shiftsData = JSON.parse(storedShifts);
          
          // Filter shifts for the current user
          if (currentUser?.id) {
            shiftsData = shiftsData.filter(shift => shift.employeeId === currentUser.id);
          }
          
          // Sort by date and clock-in time (newest first)
          shiftsData.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
          
          setShifts(shiftsData);
          
          // Group shifts by date
          const grouped = groupShiftsByDate(shiftsData);
          setGroupedShifts(grouped);
          
          // Calculate weekly hours
          const weekly = calculateWeeklyHours(shiftsData, dateRange.startDate, dateRange.endDate);
          setWeeklyHours(weekly);
        } catch (error) {
          console.error('Error parsing shift data:', error);
        }
      }
      
      setIsLoading(false);
    };
    
    loadShifts();
  }, [currentUser, dateRange]);

  const handleDateRangeChange = (e) => {
    const value = e.target.value;
    
    const now = new Date();
    let startDate, endDate;
    
    switch (value) {
      case 'this-week':
        const thisWeek = getCurrentWeekDates();
        startDate = thisWeek.startDate;
        endDate = thisWeek.endDate;
        break;
      case 'last-week':
        const lastWeekStart = new Date(now);
        lastWeekStart.setDate(now.getDate() - 7 - now.getDay());
        lastWeekStart.setHours(0, 0, 0, 0);
        
        const lastWeekEnd = new Date(lastWeekStart);
        lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
        lastWeekEnd.setHours(23, 59, 59, 999);
        
        startDate = lastWeekStart.toISOString().split('T')[0];
        endDate = lastWeekEnd.toISOString().split('T')[0];
        break;
      case 'this-month':
        const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        const thisMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        thisMonthEnd.setHours(23, 59, 59, 999);
        
        startDate = thisMonthStart.toISOString().split('T')[0];
        endDate = thisMonthEnd.toISOString().split('T')[0];
        break;
      case 'last-month':
        const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0);
        lastMonthEnd.setHours(23, 59, 59, 999);
        
        startDate = lastMonthStart.toISOString().split('T')[0];
        endDate = lastMonthEnd.toISOString().split('T')[0];
        break;
      default:
        // Default to current week
        const defaultWeek = getCurrentWeekDates();
        startDate = defaultWeek.startDate;
        endDate = defaultWeek.endDate;
    }
    
    setDateRange({ startDate, endDate });
  };
  
  // Format a date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  // Format time for display
  const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between mb-8">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Timesheet
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              View your work history and hours
            </p>
          </div>
        </div>
        
        {/* Date Range and Summary */}
        <div className="bg-white shadow rounded-lg p-6 mb-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Time Period
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {dateRange.startDate} to {dateRange.endDate}
              </p>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <select
                id="date-range"
                name="date-range"
                onChange={handleDateRangeChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="this-week">This Week</option>
                <option value="last-week">Last Week</option>
                <option value="this-month">This Month</option>
                <option value="last-month">Last Month</option>
              </select>
            </div>
          </div>
          
          {/* Summary Section */}
          <div className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <div className="bg-gray-50 overflow-hidden shadow-sm rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Total Hours
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {weeklyHours ? weeklyHours.totalHours.toFixed(2) : '0.00'}
                </dd>
              </div>
            </div>
            
            <div className="bg-gray-50 overflow-hidden shadow-sm rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Total Shifts
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {shifts.length}
                </dd>
              </div>
            </div>
            
            <div className="bg-gray-50 overflow-hidden shadow-sm rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">
                  Average Hours Per Shift
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {shifts.length > 0 && weeklyHours 
                    ? (weeklyHours.totalHours / shifts.length).toFixed(2) 
                    : '0.00'}
                </dd>
              </div>
            </div>
          </div>
        </div>
        
        {/* Timesheet Table */}
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Your Timesheet
            </h3>
          </div>
          
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
            </div>
          ) : shifts.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Clock In
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Clock Out
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Duration
                    </th>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Location
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {shifts.map((shift) => (
                    <tr key={shift.id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {formatDate(shift.date)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {formatTime(shift.clockInTime)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {formatTime(shift.clockOutTime)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {shift.duration}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {shift.clockInLocation}
                          {shift.clockInLocation !== shift.clockOutLocation && (
                            <span className="text-sm text-gray-500">
                              {" → "}{shift.clockOutLocation}
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="px-4 py-5 sm:px-6 text-center text-gray-500">
              No timesheet entries found for the selected period.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timesheet;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import ClockInButton from '../components/ClockInButton';
import ClockOutButton from '../components/ClockOutButton';
import LocationPicker from '../components/LocationPicker';
import { getCurrentLocation, getAddressFromCoordinates } from '../utils/locationUtils';
import { formatElapsedTime } from '../utils/timeUtils';

const TimeTracker = () => {
  const { currentUser } = useAuth();
  const [clockedIn, setClockedIn] = useState(false);
  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [location, setLocation] = useState('');
  const [manualLocation, setManualLocation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [elapsedTime, setElapsedTime] = useState('00:00:00');
  const [useGeolocation, setUseGeolocation] = useState(true);
  const [shiftHistory, setShiftHistory] = useState([]);

  // Check if user is clocked in from local storage
  useEffect(() => {
    const storedClockInStatus = localStorage.getItem('clockedIn');
    const storedClockInTime = localStorage.getItem('clockInTime');
    const storedLocation = localStorage.getItem('clockInLocation');
    
    if (storedClockInStatus === 'true' && storedClockInTime) {
      setClockedIn(true);
      setClockInTime(new Date(storedClockInTime));
      setLocation(storedLocation || '');
    }
    
    // Load shift history from localStorage
    const storedHistory = localStorage.getItem('shiftHistory');
    if (storedHistory) {
      try {
        setShiftHistory(JSON.parse(storedHistory));
      } catch (e) {
        console.error("Failed to parse shift history", e);
      }
    }
  }, []);

  // Update elapsed time every second when clocked in
  useEffect(() => {
    let timer;
    if (clockedIn && clockInTime) {
      timer = setInterval(() => {
        const now = new Date();
        const elapsedMs = now - new Date(clockInTime);
        setElapsedTime(formatElapsedTime(elapsedMs));
      }, 1000);
    }
    
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [clockedIn, clockInTime]);

  // Handle clock in
  const handleClockIn = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      let locationText = '';
      
      if (useGeolocation) {
        try {
          // Get current location using utility function
          const coordinates = await getCurrentLocation();
          locationText = await getAddressFromCoordinates(coordinates);
        } catch (locationError) {
          setError('Failed to get your location. Please enter it manually.');
          setUseGeolocation(false);
          setIsLoading(false);
          return;
        }
      } else {
        if (!manualLocation.trim()) {
          setError('Please enter your location');
          setIsLoading(false);
          return;
        }
        locationText = manualLocation;
      }
      
      setLocation(locationText);
      setClockedIn(true);
      const now = new Date();
      setClockInTime(now);
      
      // Save to localStorage
      localStorage.setItem('clockedIn', 'true');
      localStorage.setItem('clockInTime', now.toISOString());
      localStorage.setItem('clockInLocation', locationText);
      
      setError('');
    } catch (err) {
      setError(`Failed to clock in: ${err.message || 'Unknown error'}`);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle clock out
  const handleClockOut = async () => {
    try {
      setIsLoading(true);
      setError('');
      
      let clockOutLocation = '';
      
      if (useGeolocation) {
        try {
          // Get current location
          const coordinates = await getCurrentLocation();
          clockOutLocation = await getAddressFromCoordinates(coordinates);
        } catch (locationError) {
          setError('Failed to get your location for clock out. Using clock-in location.');
          clockOutLocation = location;
        }
      } else {
        if (!manualLocation.trim()) {
          clockOutLocation = location; // Use clock-in location as fallback
        } else {
          clockOutLocation = manualLocation;
        }
      }
      
      const now = new Date();
      setClockOutTime(now);
      
      // Calculate duration
      const durationMs = now - new Date(clockInTime);
      const hours = Math.floor(durationMs / (1000 * 60 * 60));
      const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
      const duration = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      
      // Create shift record
      const newShift = {
        id: Date.now().toString(),
        employeeId: currentUser?.id || 'unknown',
        employeeName: currentUser?.name || 'Unknown User',
        date: now.toISOString().split('T')[0],
        clockInTime: new Date(clockInTime).toISOString(),
        clockOutTime: now.toISOString(),
        clockInLocation: location,
        clockOutLocation,
        duration,
        durationMs,
        notes: '' // Can be updated later
      };
      
      // Update shift history
      const updatedHistory = [newShift, ...shiftHistory];
      setShiftHistory(updatedHistory);
      localStorage.setItem('shiftHistory', JSON.stringify(updatedHistory));
      
      // Clear clock-in state
      setClockedIn(false);
      setClockInTime(null);
      setLocation('');
      
      // Clear localStorage clock-in data
      localStorage.removeItem('clockedIn');
      localStorage.removeItem('clockInTime');
      localStorage.removeItem('clockInLocation');
      
    } catch (err) {
      setError(`Failed to clock out: ${err.message || 'Unknown error'}`);
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Toggle between automatic and manual location
  const toggleLocationMethod = () => {
    setUseGeolocation(!useGeolocation);
    if (!useGeolocation) {
      setManualLocation('');
    }
  };

  // Handle manual location input change
  const handleLocationChange = (e) => {
    setManualLocation(e.target.value);
  };
  
  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="px-6 py-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-6">Time Tracker</h2>
            
            {error && (
              <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6">
                <p>{error}</p>
              </div>
            )}
            
            <div className="mb-8">
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div className="mb-4 md:mb-0">
                  <h3 className="text-lg font-medium text-gray-700">Current Status</h3>
                  <p className="mt-1 text-3xl font-semibold text-gray-900">
                    {clockedIn ? 'Clocked In' : 'Clocked Out'}
                  </p>
                  {clockedIn && (
                    <>
                      <p className="mt-2 text-sm text-gray-500">
                        Since: {new Date(clockInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        Location: {location}
                      </p>
                      <p className="mt-1 text-lg font-medium text-gray-800">
                        Time Elapsed: {elapsedTime}
                      </p>
                    </>
                  )}
                </div>
                
                <div>
                  {clockedIn ? (
                    <ClockOutButton onClick={handleClockOut} isLoading={isLoading} />
                  ) : (
                    <ClockInButton onClick={handleClockIn} isLoading={isLoading} />
                  )}
                </div>
              </div>
            </div>
            
            <div className="border-t border-gray-200 pt-6">
              <h3 className="text-lg font-medium text-gray-700 mb-4">Location Settings</h3>
              
              <div className="flex items-center mb-4">
                <button 
                  onClick={toggleLocationMethod}
                  className="px-4 py-2 border rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  disabled={clockedIn}
                >
                  {useGeolocation ? 'Enter Location Manually' : 'Use Current Location'}
                </button>
              </div>
              
              {!useGeolocation && (
                <div className="mt-4">
                  <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                    Enter your location
                  </label>
                  <input
                    type="text"
                    id="location"
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="e.g., Hospice Main Building, 123 Care St."
                    value={manualLocation}
                    onChange={handleLocationChange}
                    disabled={clockedIn}
                  />
                </div>
              )}
            </div>
            
            {/* Recent shifts summary */}
            {shiftHistory.length > 0 && (
              <div className="border-t border-gray-200 pt-6 mt-6">
                <h3 className="text-lg font-medium text-gray-700 mb-4">Recent Shifts</h3>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Clock In
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Clock Out
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {shiftHistory.slice(0, 5).map((shift) => (
                        <tr key={shift.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {new Date(shift.date).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(shift.clockInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(shift.clockOutTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {shift.duration}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeTracker;
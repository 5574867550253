import React from 'react';

/**
 * Simple bar chart component for displaying hours worked
 * 
 * @param {Object} props Component props
 * @param {Array} props.data Array of objects with name and hours properties
 * @param {string} props.barColor Custom color for bars (optional)
 */
const HoursChart = ({ data, barColor = '#4f46e5' }) => {
  if (!data || data.length === 0) {
    return <div className="flex items-center justify-center h-full">No data available</div>;
  }

  // Find the maximum value for scaling
  const maxHours = Math.max(...data.map(item => item.hours));
  
  return (
    <div className="h-full flex flex-col">
      <div className="flex-grow flex items-end">
        {data.map((item, index) => {
          // Calculate height percentage based on the max value
          const heightPercentage = (item.hours / maxHours) * 100;
          
          return (
            <div
              key={index}
              className="flex flex-col items-center justify-end flex-1 px-1"
            >
              <div
                className="w-full rounded-t-sm mb-1"
                style={{
                  height: `${heightPercentage}%`,
                  backgroundColor: barColor,
                  minHeight: '4px' // Ensure there's at least a visible line for values close to 0
                }}
              ></div>
              <div className="text-xs font-medium text-gray-900 truncate w-full text-center">
                {item.hours.toFixed(1)}
              </div>
            </div>
          );
        })}
      </div>
      
      <div className="mt-2 pt-2 border-t border-gray-200">
        <div className="flex justify-between">
          {data.map((item, index) => (
            <div 
              key={index} 
              className="text-xs font-medium text-gray-500 truncate"
              style={{ 
                maxWidth: `${100 / data.length}%`,
                textAlign: 'center',
                flex: '1'
              }}
            >
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HoursChart;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Volunteer from './pages/Volunteer';
import Contact from './pages/Contact';
import ErrorPage from './sections/error-page';
import Faqs from './pages/Faqs';
import Login from './pages/Login';
import PortalLayout from './portal/PortalLayout';
import EmployeeDashboard from './portal/EmployeeDashboard';
import AdminDashboard from './portal/AdminDashboard';
import TimeTracker from './portal/TimeTracker';
import Timesheet from './portal/Timesheet';
import AdminReports from './portal/AdminReports';
import UserManagement from './portal/UserManagement';
import { AuthProvider } from './context/AuthContext';
import { Navigate } from 'react-router-dom';

// Protected route component
const ProtectedRoute = ({ children, requiredRole = 'employee' }) => {
  // This is a simplified version - real implementation would check auth state
  const isAuthenticated = localStorage.getItem('isAuthenticated');
  const userRole = localStorage.getItem('userRole');
  
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  if (requiredRole === 'admin' && userRole !== 'admin') {
    return <Navigate to="/portal/dashboard" />;
  }
  
  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "",
        element: <Home/>,
      },
      {
        path: "home",
        element: <Home/>,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "volunteer",
        element: <Volunteer />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
      {
        path: "login",
        element: <Login />,
      },
    ]
  },
  // Employee Portal Routes - with protection
  {
    path: "/portal",
    element: <ProtectedRoute><PortalLayout /></ProtectedRoute>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "dashboard",
        element: <EmployeeDashboard />,
      },
      {
        path: "time-tracker",
        element: <TimeTracker />,
      },
      {
        path: "timesheet",
        element: <Timesheet />,
      },
    ]
  },
  // Admin Portal Routes - with protection
  {
    path: "/admin",
    element: <ProtectedRoute requiredRole="admin"><PortalLayout isAdmin={true} /></ProtectedRoute>,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "reports",
        element: <AdminReports />,
      },
      {
        path: "users",
        element: <UserManagement />,
      },
    ]
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(sendToVercelAnalytics);
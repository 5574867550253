import React from "react";
import HeaderSection from "../sections/header-section";
import AboutUsSection from "../sections/aboutus-section";
import ServicesSection from "../sections/services-section";


const Home = () => {
  return (
    <div className="flex flex-col w-full">
      <HeaderSection />
      <div className="container mx-auto px-4">
        <AboutUsSection />
        <ServicesSection />
      </div>
    </div>
  );
};

export default Home;

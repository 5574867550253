/**
 * Utility functions for time-related operations
 */

/**
 * Format milliseconds into a human-readable HH:MM:SS format
 * @param {number} milliseconds - Time in milliseconds
 * @returns {string} Formatted time string
 */
export const formatElapsedTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((milliseconds % (1000 * 60)) / 1000);
    
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  /**
   * Calculate the total hours worked in a week
   * @param {Array} shifts - Array of shift objects with durationMs property
   * @param {string} startDate - Start date of the week (YYYY-MM-DD)
   * @param {string} endDate - End date of the week (YYYY-MM-DD)
   * @returns {object} Object containing total hours and formatted time
   */
  export const calculateWeeklyHours = (shifts, startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    // Filter shifts within the date range
    const weekShifts = shifts.filter(shift => {
      const shiftDate = new Date(shift.date);
      return shiftDate >= start && shiftDate <= end;
    });
    
    // Calculate total duration in milliseconds
    const totalMs = weekShifts.reduce((total, shift) => total + shift.durationMs, 0);
    
    // Convert to hours with 2 decimal places
    const totalHours = (totalMs / (1000 * 60 * 60)).toFixed(2);
    
    return {
      totalHours: parseFloat(totalHours),
      formattedTotal: formatElapsedTime(totalMs)
    };
  };
  
  /**
   * Group shifts by date
   * @param {Array} shifts - Array of shift objects
   * @returns {Object} Object with dates as keys and arrays of shifts as values
   */
  export const groupShiftsByDate = (shifts) => {
    return shifts.reduce((grouped, shift) => {
      const date = shift.date;
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(shift);
      return grouped;
    }, {});
  };
  
  /**
   * Get the start and end dates for the current week
   * @returns {Object} Object containing start and end dates
   */
  export const getCurrentWeekDates = () => {
    const now = new Date();
    const day = now.getDay(); // 0 = Sunday, 1 = Monday, etc.
    
    // Calculate the date of the Sunday at the start of the week
    const startDate = new Date(now);
    startDate.setDate(now.getDate() - day);
    startDate.setHours(0, 0, 0, 0);
    
    // Calculate the date of the Saturday at the end of the week
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    endDate.setHours(23, 59, 59, 999);
    
    return {
      startDate: startDate.toISOString().split('T')[0],
      endDate: endDate.toISOString().split('T')[0]
    };
  };
  
  /**
   * Check if a time entry is flagged based on criteria
   * (e.g., unusual hours, missing clock out, etc.)
   * @param {Object} shift - Shift object to check
   * @returns {Object} Object with flag status and reason
   */
  export const checkTimeEntryFlags = (shift) => {
    const flags = {
      flagged: false,
      reasons: []
    };
    
    // Check for unusually long shifts (more than 12 hours)
    if (shift.durationMs > 12 * 60 * 60 * 1000) {
      flags.flagged = true;
      flags.reasons.push('Unusually long shift (>12 hours)');
    }
    
    // Check for shifts outside normal hours (before 6am or after 9pm)
    const clockInHour = new Date(shift.clockInTime).getHours();
    const clockOutHour = new Date(shift.clockOutTime).getHours();
    
    if (clockInHour < 6) {
      flags.flagged = true;
      flags.reasons.push('Early clock-in (before 6am)');
    }
    
    if (clockOutHour >= 21) {
      flags.flagged = true;
      flags.reasons.push('Late clock-out (after 9pm)');
    }
    
    // Check for different clock-in and clock-out locations
    if (shift.clockInLocation !== shift.clockOutLocation) {
      flags.flagged = true;
      flags.reasons.push('Different clock-in and clock-out locations');
    }
    
    return flags;
  };
import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { Heart, Clock, Calendar, AlertCircle } from 'lucide-react';
import emailjs from "@emailjs/browser";

const Volunteer = () => {
  emailjs.init("Kdod9F42WL2j3AIlw");

  const form = useRef();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    availability: '',
    experience: '',
    reason: '',
    agreeToBackground: false,
    agreeToContact: false
  });

  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState({ type: "", message: "" });

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid phone number";
    }

    if (!formData.availability) {
      newErrors.availability = "Please select your availability";
    }

    if (!formData.reason.trim()) {
      newErrors.reason = "Please tell us why you want to volunteer";
    }

    if (!formData.agreeToBackground) {
      newErrors.agreeToBackground = "Background check consent is required";
    }

    if (!formData.agreeToContact) {
      newErrors.agreeToContact = "Communication consent is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSubmitStatus({ type: "info", message: "Submitting your application..." });

      const templateParams = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
        city: formData.city,
        state: formData.state,
        zip: formData.zip,
        availability: formData.availability,
        experience: formData.experience,
        reason: formData.reason,
        agreeToBackground: formData.agreeToBackground ? "Yes" : "No",
        agreeToContact: formData.agreeToContact ? "Yes" : "No",
        submission_date: new Date().toLocaleString()
      };

      try {
        await emailjs.send(
          "service_tl22u13",
          "template_tlxudhp",
          templateParams
        );
        
        setSubmitStatus({
          type: "success",
          message: "Thank you for your volunteer application! We will contact you soon.",
        });

        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          availability: '',
          experience: '',
          reason: '',
          agreeToBackground: false,
          agreeToContact: false
        });
      } catch (error) {
        console.error("EmailJS Error:", error);
        setSubmitStatus({
          type: "error",
          message: "There was an error submitting your application. Please try again later.",
        });
      }
    }
  };

  const benefits = [
    {
      icon: <Heart className="w-6 h-6" />,
      title: "Make a Difference",
      description: "Impact lives by providing comfort and support to patients and families."
    },
    {
      icon: <Clock className="w-6 h-6" />,
      title: "Flexible Schedule",
      description: "Choose volunteer hours that work best with your availability."
    },
    {
      icon: <Calendar className="w-6 h-6" />,
      title: "Valuable Experience",
      description: "Gain meaningful healthcare and caregiving experience."
    }
  ];

  return (
    <div className="min-h-screen pt-20 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-3xl mx-auto text-center mb-16"
        >
          <h1 className="text-4xl font-bold text-gray-900 mb-6">
            Join Our Volunteer Team
          </h1>
          <p className="text-lg text-gray-600">
            Share your time and compassion with those who need it most. Join our dedicated team of volunteers making a difference in our community.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
          {benefits.map((benefit, index) => (
            <motion.div
              key={benefit.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="inline-flex p-3 rounded-full bg-maluhia-blue/10 text-maluhia-blue mb-4">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                {benefit.title}
              </h3>
              <p className="text-gray-600">
                {benefit.description}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-8"
        >
          <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">
            Volunteer Application Form
          </h2>

          {submitStatus.message && (
            <div
              className={`mb-6 p-4 rounded-lg ${
                submitStatus.type === "success"
                  ? "bg-green-50 text-green-700"
                  : submitStatus.type === "error"
                  ? "bg-red-50 text-red-700"
                  : "bg-blue-50 text-blue-700"
              }`}
            >
              <p className="text-sm">{submitStatus.message}</p>
            </div>
          )}

          <form ref={form} onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="firstName">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className={`w-full rounded-lg border p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue ${
                    errors.firstName ? "border-red-500" : "border-gray-200"
                  }`}
                />
                {errors.firstName && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.firstName}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="lastName">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className={`w-full rounded-lg border p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue ${
                    errors.lastName ? "border-red-500" : "border-gray-200"
                  }`}
                />
                {errors.lastName && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.lastName}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full rounded-lg border p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue ${
                    errors.email ? "border-red-500" : "border-gray-200"
                  }`}
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.email}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="phone">
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`w-full rounded-lg border p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue ${
                    errors.phone ? "border-red-500" : "border-gray-200"
                  }`}
                />
                {errors.phone && (
                  <p className="mt-1 text-sm text-red-500 flex items-center">
                    <AlertCircle className="w-4 h-4 mr-1" />
                    {errors.phone}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="address">
                Street Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue"
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-4 gap-6">
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="city">
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="state">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="zip">
                  ZIP Code
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  value={formData.zip}
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="availability">
                Availability
              </label>
              <select
                id="availability"
                name="availability"
                value={formData.availability}
                onChange={handleChange}
                className={`w-full rounded-lg border p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue ${
                  errors.availability ? "border-red-500" : "border-gray-200"
                }`}
              >
                <option value="">Select your availability</option>
                <option value="weekday-mornings">Weekday Mornings</option>
                <option value="weekday-afternoons">Weekday Afternoons</option>
                <option value="weekday-evenings">Weekday Evenings</option>
                <option value="weekends">Weekends</option>
                <option value="flexible">Flexible</option>
              </select>
              {errors.availability && (
                <p className="mt-1 text-sm text-red-500 flex items-center">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  {errors.availability}
                </p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="experience">
                Previous Volunteer Experience
              </label>
              <textarea
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                rows="3"
                className="w-full rounded-lg border border-gray-200 p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue"
              ></textarea>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="reason">
                Why do you want to volunteer with us?
              </label>
              <textarea
                id="reason"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
                rows="3"
                className={`w-full rounded-lg border p-3 text-sm focus:border-maluhia-blue focus:ring-maluhia-blue ${
                  errors.reason ? "border-red-500" : "border-gray-200"
                }`}
              ></textarea>
              {errors.reason && (
                <p className="mt-1 text-sm text-red-500 flex items-center">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  {errors.reason}
                </p>
              )}
            </div>

            <div className="space-y-4">
              <label className="flex items-start gap-3">
                <input
                  type="checkbox"
                  name="agreeToBackground"
                  checked={formData.agreeToBackground}
                  onChange={handleChange}
                  className={`mt-1 h-4 w-4 rounded border-gray-300 text-maluhia-blue focus:ring-maluhia-blue ${
                    errors.agreeToBackground ? "border-red-500" : ""
                  }`}
                />
                <span className="text-sm text-gray-600">
                  I understand and agree to undergo a background check if selected as a volunteer.
                </span>
              </label>
              {errors.agreeToBackground && (
                <p className="mt-1 text-sm text-red-500 flex items-center">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  {errors.agreeToBackground}
                </p>
              )}

              <label className="flex items-start gap-3">
                <input
                  type="checkbox"
                  name="agreeToContact"
                  checked={formData.agreeToContact}
                  onChange={handleChange}
                  className={`mt-1 h-4 w-4 rounded border-gray-300 text-maluhia-blue focus:ring-maluhia-blue ${
                    errors.agreeToContact ? "border-red-500" : ""
                  }`}
                />
                <span className="text-sm text-gray-600">
                  I agree to receive communications about my volunteer application via phone, email, and SMS. Message and data rates may apply.
                </span>
              </label>
              {errors.agreeToContact && (
                <p className="mt-1 text-sm text-red-500 flex items-center">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  {errors.agreeToContact}
                </p>
              )}
            </div>

            <button
              type="submit"
              className="w-full rounded-lg bg-maluhia-blue px-5 py-3 text-white font-medium transition hover:bg-maluhia-blue/90 focus:outline-none focus:ring-2 focus:ring-maluhia-blue focus:ring-offset-2"
            >
              Submit Application
            </button>

            <p className="text-xs text-gray-500 text-center mt-4 flex items-center justify-center gap-1">
              <AlertCircle className="w-4 h-4" />
              All volunteer positions are subject to availability and screening process
            </p>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Volunteer;
import React from "react";
import image from "../assets/eolc.jpg"

import { motion } from 'framer-motion';
import { Heart, Users, UserPlus, Flower } from 'lucide-react';

const ServicesSection = () => {
  const services = [
    {
      icon: <Heart className="w-8 h-8" />,
      title: "Empowered End-of-Life Care",
      description: "Empower and engage patients and their caregivers in understanding and utilizing end-of-life care resources and support.",
    },
    {
      icon: <Users className="w-8 h-8" />,
      title: "Comprehensive Family Support",
      description: "Delivering comprehensive care that addresses the physical, social, emotional, and spiritual needs of both the patient and their family.",
    },
    {
      icon: <UserPlus className="w-8 h-8" />,
      title: "Team Collaboration",
      description: "Optimal utilization of a hospice team, which includes physicians, nurses, social workers, and therapists collaborating seamlessly to ensure the delivery of top-notch care.",
    },
    {
      icon: <Flower className="w-8 h-8" />,
      title: "Grief Support",
      description: "Continuing bereavement support for the family in the aftermath of their loss.",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <section id="services" className="bg-white py-16 lg:py-24">
      <div className="container px-6 mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-800 mb-4">Our Services</h2>
          <div className="flex justify-center gap-1">
            {[1, 2, 3].map((_, index) => (
              <motion.div
                key={index}
                initial={{ scaleX: 0 }}
                whileInView={{ scaleX: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: index * 0.2 }}
                className="h-1 w-16 bg-maluhia-blue rounded-full"
              />
            ))}
          </div>
        </motion.div>

        <div className="flex flex-col lg:flex-row items-center gap-12">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:w-1/2"
          >
            {services.map((service, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                className="group relative p-6 bg-gray-50 rounded-xl hover:bg-maluhia-blue/5 transition-colors duration-300"
              >
                <div className="absolute -top-4 left-6 p-3 bg-maluhia-blue/10 rounded-lg text-maluhia-blue group-hover:bg-maluhia-blue group-hover:text-white transition-colors duration-300">
                  {service.icon}
                </div>
                
                <div className="mt-4">
                  <h3 className="text-xl font-semibold text-gray-800 mb-2">
                    {service.title}
                  </h3>
                  <p className="text-gray-600 leading-relaxed">
                    {service.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="hidden lg:block lg:w-1/2"
          >
            <div className="relative">
              <div className="absolute inset-0 bg-maluhia-blue/10 mix-blend-multiply rounded-2xl" />
              <img
                src={image}
                alt="Hospice care services"
                className="w-full h-[34rem] object-cover rounded-2xl shadow-lg"
              />
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
import React, { useState, useEffect } from 'react';
import hawaiiImage from '../assets/hawaii.jpg'; // Make sure the path is correct


import { motion, AnimatePresence } from 'framer-motion';

const HeaderSection = () => {
  const words = ["PEACE", "QUIET", "SECURITY", "TRANQUILITY"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsVisible(true);
      }, 500);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section
      className="relative flex items-center justify-start min-h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${hawaiiImage})`,
      }}
      role="banner"
      aria-label="Welcome to Maluhia Hospice"
    >
      {/* Gradient overlay instead of simple opacity */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-black/50 z-0"></div>

      <div className="max-w-screen-xl px-4 py-32 w-full relative z-10">
        <div className="max-w-3xl mx-auto">
          <AnimatePresence mode="wait">
            <motion.div
              key={currentWordIndex}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
              className="h-20"
            >
              <h1 className="text-6xl md:text-7xl font-extrabold text-maluhia-blue tracking-tight">
                {words[currentWordIndex]}
              </h1>
            </motion.div>
          </AnimatePresence>

          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.8 }}
            className="mt-6 text-lg md:text-xl text-white/90 leading-relaxed"
          >
            Maluhia Hospice is dedicated to our community, patients, and families with unwavering excellence in hospice care. We understand the unique emotions and uncertainties that come with end-of-life challenges. Our commitment is to bring hope, enrichment, and personalized support to every patient and family we serve.
          </motion.p>

          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6, duration: 0.8 }}
            className="mt-8 flex flex-wrap gap-4"
          >
            <a
              href="/contact"
              className="group relative inline-flex items-center overflow-hidden rounded-full bg-maluhia-blue px-8 py-3 text-white focus:outline-none focus:ring-2 focus:ring-maluhia-blue focus:ring-offset-2"
            >
              <span className="absolute -end-full transition-all group-hover:end-4">
                →
              </span>

              <span className="text-sm font-medium transition-all group-hover:me-4">
                Connect with us
              </span>
            </a>

            <a
              href="/about"
              className="group relative inline-flex items-center overflow-hidden rounded-full border border-current px-8 py-3 text-white focus:outline-none focus:ring-2 focus:ring-maluhia-blue focus:ring-offset-2"
            >
              <span className="absolute -end-full transition-all group-hover:end-4">
                →
              </span>

              <span className="text-sm font-medium transition-all group-hover:me-4">
                Learn More
              </span>
            </a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeaderSection;
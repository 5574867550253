import React from 'react'
import image from '../assets/care.jpg'

import { motion } from 'framer-motion';
import { Heart, Users, Clock, Phone } from 'lucide-react';

const AboutUsSection = () => {
  const features = [
    {
      icon: <Heart className="w-6 h-6 text-maluhia-blue" />,
      title: "Compassionate Care",
      description: "Providing gentle, understanding support for every patient."
    },
    {
      icon: <Users className="w-6 h-6 text-maluhia-blue" />,
      title: "Family Support",
      description: "Guiding families through every step of the journey."
    },
    {
      icon: <Clock className="w-6 h-6 text-maluhia-blue" />,
      title: "24/7 Availability",
      description: "Round-the-clock care when you need us most."
    }
  ];

  return (
    <section className="py-16 lg:py-24">
      <div className="mx-auto max-w-screen-2xl px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          <motion.div 
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="relative"
          >
            <div className="relative h-96 lg:h-full overflow-hidden rounded-2xl">
              <div className="absolute inset-0 bg-maluhia-blue/10 mix-blend-multiply rounded-2xl" />
              <img
                alt="Caring medical professional with patient"
                src={image}
                className="absolute inset-0 h-full w-full object-cover"
              />
            </div>
          </motion.div>

          <motion.div 
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="flex flex-col justify-center lg:pl-8"
          >
            <span className="text-sm font-semibold uppercase tracking-wider text-maluhia-blue">
              Our Purpose
            </span>

            <h2 className="mt-4 text-3xl font-bold tracking-tight sm:text-4xl">
              Bringing Peace and Comfort
            </h2>

            <p className="mt-6 text-lg leading-relaxed text-gray-600">
              Maluhia Hospice Care, PLLC is dedicated to our mission of providing the highest quality of care, respect, compassion, and support to all patients and families during their end-of-life journey, ensuring they experience peace, tranquility, and serenity.
            </p>

            <div className="mt-8 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  className="flex flex-col items-start"
                >
                  <div className="rounded-full bg-maluhia-blue/10 p-3">
                    {feature.icon}
                  </div>
                  <h3 className="mt-4 font-semibold">{feature.title}</h3>
                  <p className="mt-2 text-sm text-gray-600">{feature.description}</p>
                </motion.div>
              ))}
            </div>

            <div className="mt-8 flex flex-wrap gap-4">
              <a
                href="/contact"
                className="inline-flex items-center gap-2 rounded-full bg-maluhia-blue px-8 py-3 text-sm font-medium text-white transition hover:bg-maluhia-blue/90 focus:outline-none focus:ring-2 focus:ring-maluhia-blue focus:ring-offset-2"
              >
                Get in Touch
                <Phone className="w-4 h-4" />
              </a>
              
              <a
                href="/about"
                className="inline-flex items-center gap-2 rounded-full border border-maluhia-blue px-8 py-3 text-sm font-medium text-maluhia-blue transition hover:bg-maluhia-blue/10 focus:outline-none focus:ring-2 focus:ring-maluhia-blue focus:ring-offset-2"
              >
                Learn More
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection;
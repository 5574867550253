/**
 * Utility functions for location-related operations
 */

/**
 * Get the current geolocation coordinates using the browser's Geolocation API
 * @returns {Promise} Promise that resolves to coordinates object
 */
export const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser'));
        return;
      }
      
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy
          });
        },
        (error) => {
          let errorMessage = 'Unknown error occurred while retrieving location.';
          
          switch (error.code) {
            case error.PERMISSION_DENIED:
              errorMessage = 'Location permission denied. Please enable location services.';
              break;
            case error.POSITION_UNAVAILABLE:
              errorMessage = 'Location information is unavailable.';
              break;
            case error.TIMEOUT:
              errorMessage = 'Location request timed out.';
              break;
          }
          
          reject(new Error(errorMessage));
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0
        }
      );
    });
  };
  
  /**
   * Convert coordinates to address using reverse geocoding
   * In a real application, you would use a service like Google Maps Geocoding API
   * This is a mock implementation that simulates predefined locations
   * 
   * @param {Object} coordinates - Latitude and longitude
   * @returns {Promise} Promise that resolves to address string
   */
  export const getAddressFromCoordinates = async (coordinates) => {
    // Mock implementation - in a real app, you'd call a geocoding service API
    // For development, we'll simulate some predefined locations based on lat/lng ranges
    
    const { latitude, longitude } = coordinates;
    
    // Simulate API call delay
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Mock address database (these would be your actual hospice locations)
    const knownLocations = [
      {
        name: "Maluhia Hospice Main Building",
        lat: 21.3069,
        lng: -157.8583,
        radius: 0.01 // roughly 1km
      },
      {
        name: "Maluhia Hospice East Wing",
        lat: 21.3079,
        lng: -157.8573,
        radius: 0.005
      },
      {
        name: "Maluhia Administrative Office",
        lat: 21.3059,
        lng: -157.8593,
        radius: 0.008
      }
    ];
    
    // Check if coordinates are within any known location
    for (const location of knownLocations) {
      const distance = calculateDistance(
        latitude, 
        longitude, 
        location.lat, 
        location.lng
      );
      
      if (distance <= location.radius) {
        return location.name;
      }
    }
    
    // If no match, return coordinates with a generic label
    return `Unknown Location (${latitude.toFixed(6)}, ${longitude.toFixed(6)})`;
  };
  
  /**
   * Calculate the Haversine distance between two sets of coordinates
   * @param {number} lat1 - Latitude of first point
   * @param {number} lon1 - Longitude of first point  
   * @param {number} lat2 - Latitude of second point
   * @param {number} lon2 - Longitude of second point
   * @returns {number} Distance in decimal degrees
   */
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = R * c; // Distance in km
    
    // Convert to decimal degrees (approximate)
    return d / 111;
  };
  
  /**
   * Convert degrees to radians
   * @param {number} deg - Degrees
   * @returns {number} Radians
   */
  const deg2rad = (deg) => {
    return deg * (Math.PI/180);
  };
  
  /**
   * Validate if a location is authorized for clock-in/out
   * @param {string} location - Location name or coordinates
   * @param {Array} authorizedLocations - Array of authorized location names
   * @returns {boolean} Whether location is authorized
   */
  export const isAuthorizedLocation = (location, authorizedLocations = []) => {
    // If no restrictions set, all locations are valid
    if (!authorizedLocations || authorizedLocations.length === 0) {
      return true;
    }
    
    // Check if the location matches any authorized location
    return authorizedLocations.some(authorized => 
      location.toLowerCase().includes(authorized.toLowerCase())
    );
  };
import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';
import { checkTimeEntryFlags, getCurrentWeekDates } from '../utils/timeUtils';
import HoursChart from '../components/HoursChart';

const AdminDashboard = () => {
  const { currentUser } = useAuth();
  const [employees, setEmployees] = useState([]);
  const [weeklyData, setWeeklyData] = useState(null);
  const [activeShifts, setActiveShifts] = useState([]);
  const [flaggedEntries, setFlaggedEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState(() => getCurrentWeekDates());

  // Simulate loading employee data from API/localStorage
  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        
        // In a real app, this would come from an API
        // For now, we'll use mock data
        await fetchMockData();
        
      } catch (error) {
        console.error('Error loading admin data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
    loadData();
  }, [dateRange]);

  // Mock function to fetch data (simulates API calls)
  const fetchMockData = async () => {
    // Simulate API delay
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Mock employees
    const mockEmployees = [
      { id: '1', name: 'Jane Smith', role: 'Nurse', department: 'Care' },
      { id: '2', name: 'Robert Johnson', role: 'Nurse Aide', department: 'Care' },
      { id: '3', name: 'Maria Garcia', role: 'Social Worker', department: 'Support' },
      { id: '4', name: 'David Lee', role: 'Chaplain', department: 'Support' },
      { id: '5', name: 'Susan Williams', role: 'Volunteer Coordinator', department: 'Admin' },
    ];
    
    // Mock shifts with some flagged entries
    const mockShifts = [];
    
    // Generate random shifts for each employee
    mockEmployees.forEach(employee => {
      // Start from the beginning of the week
      const weekStart = new Date(dateRange.startDate);
      
      // Generate 5 shifts per week per employee (typical work week)
      for (let i = 0; i < 5; i++) {
        const shiftDate = new Date(weekStart);
        shiftDate.setDate(weekStart.getDate() + i);
        
        // Random start time between 7am and 9am
        const startHour = 7 + Math.floor(Math.random() * 2);
        const startMinute = Math.floor(Math.random() * 60);
        
        const clockInTime = new Date(shiftDate);
        clockInTime.setHours(startHour, startMinute, 0, 0);
        
        // Random duration between 7 and 9 hours
        const durationHours = 7 + Math.floor(Math.random() * 2);
        const durationMinutes = Math.floor(Math.random() * 60);
        
        const clockOutTime = new Date(clockInTime);
        clockOutTime.setHours(clockInTime.getHours() + durationHours, clockInTime.getMinutes() + durationMinutes, 0, 0);
        
        // Calculate duration in ms
        const durationMs = clockOutTime - clockInTime;
        
        // Create shift object
        const shift = {
          id: `shift-${employee.id}-${i}`,
          employeeId: employee.id,
          employeeName: employee.name,
          date: shiftDate.toISOString().split('T')[0],
          clockInTime: clockInTime.toISOString(),
          clockOutTime: clockOutTime.toISOString(),
          clockInLocation: 'Maluhia Hospice Main Building',
          clockOutLocation: i % 5 === 0 ? 'Maluhia Hospice East Wing' : 'Maluhia Hospice Main Building', // Occasionally different location
          duration: formatDuration(durationMs),
          durationMs,
          department: employee.department,
          role: employee.role
        };
        
        mockShifts.push(shift);
      }
    });
    
    // Add one active shift
    const activeEmployee = mockEmployees[0];
    const now = new Date();
    const clockInTime = new Date(now);
    clockInTime.setHours(clockInTime.getHours() - 3); // Started 3 hours ago
    
    const activeShift = {
      id: `active-shift-${activeEmployee.id}`,
      employeeId: activeEmployee.id,
      employeeName: activeEmployee.name,
      date: now.toISOString().split('T')[0],
      clockInTime: clockInTime.toISOString(),
      clockInLocation: 'Maluhia Hospice Main Building',
      department: activeEmployee.department,
      role: activeEmployee.role
    };
    
    // Set employees state
    setEmployees(mockEmployees);
    
    // Check for flagged entries
    const flags = mockShifts
      .map(shift => ({
        shift,
        flags: checkTimeEntryFlags(shift)
      }))
      .filter(item => item.flags.flagged);
    
    setFlaggedEntries(flags);
    
    // Set active shifts
    setActiveShifts([activeShift]);
    
    // Process weekly data
    processWeeklyData(mockEmployees, mockShifts);
  };
  
  // Process weekly data for charts and summary
  const processWeeklyData = (employees, shifts) => {
    // Calculate hours per day
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    
    // Initialize data structure
    const weeklyHours = {
      byDay: daysOfWeek.map(day => ({ name: day, hours: 0 })),
      byDepartment: {},
      byEmployee: employees.map(emp => ({
        id: emp.id,
        name: emp.name,
        hours: 0,
        shifts: 0
      })),
      totalHours: 0,
      totalShifts: shifts.length
    };
    
    // Process each shift
    shifts.forEach(shift => {
      const shiftDate = new Date(shift.date);
      const dayOfWeek = daysOfWeek[shiftDate.getDay()];
      const hoursDuration = shift.durationMs / (1000 * 60 * 60);
      
      // Add to day total
      const dayIndex = weeklyHours.byDay.findIndex(d => d.name === dayOfWeek);
      if (dayIndex >= 0) {
        weeklyHours.byDay[dayIndex].hours += hoursDuration;
      }
      
      // Add to department total
      const dept = shift.department || 'Other';
      if (!weeklyHours.byDepartment[dept]) {
        weeklyHours.byDepartment[dept] = 0;
      }
      weeklyHours.byDepartment[dept] += hoursDuration;
      
      // Add to employee total
      const empIndex = weeklyHours.byEmployee.findIndex(e => e.id === shift.employeeId);
      if (empIndex >= 0) {
        weeklyHours.byEmployee[empIndex].hours += hoursDuration;
        weeklyHours.byEmployee[empIndex].shifts += 1;
      }
      
      // Add to total
      weeklyHours.totalHours += hoursDuration;
    });
    
    // Sort employees by hours worked (descending)
    weeklyHours.byEmployee.sort((a, b) => b.hours - a.hours);
    
    // Convert department data to array for charting
    weeklyHours.byDepartmentArray = Object.entries(weeklyHours.byDepartment).map(([name, hours]) => ({
      name,
      hours: Number(hours.toFixed(2))
    }));
    
    // Round values for display
    weeklyHours.byDay = weeklyHours.byDay.map(day => ({
      ...day,
      hours: Number(day.hours.toFixed(2))
    }));
    
    weeklyHours.byEmployee = weeklyHours.byEmployee.map(emp => ({
      ...emp,
      hours: Number(emp.hours.toFixed(2))
    }));
    
    weeklyHours.totalHours = Number(weeklyHours.totalHours.toFixed(2));
    
    setWeeklyData(weeklyHours);
  };
  
  // Format duration helper
  const formatDuration = (milliseconds) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };
  
  // Handle date range change
  const handleDateRangeChange = (e) => {
    // Get the selected value (e.g., "this-week", "last-week", etc.)
    const value = e.target.value;
    
    const now = new Date();
    let startDate, endDate;
    
    switch (value) {
      case 'this-week':
        const thisWeek = getCurrentWeekDates();
        startDate = thisWeek.startDate;
        endDate = thisWeek.endDate;
        break;
      case 'last-week':
        const lastWeekStart = new Date(now);
        lastWeekStart.setDate(now.getDate() - 7 - now.getDay());
        lastWeekStart.setHours(0, 0, 0, 0);
        
        const lastWeekEnd = new Date(lastWeekStart);
        lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
        lastWeekEnd.setHours(23, 59, 59, 999);
        
        startDate = lastWeekStart.toISOString().split('T')[0];
        endDate = lastWeekEnd.toISOString().split('T')[0];
        break;
      case 'this-month':
        const thisMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        const thisMonthEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        thisMonthEnd.setHours(23, 59, 59, 999);
        
        startDate = thisMonthStart.toISOString().split('T')[0];
        endDate = thisMonthEnd.toISOString().split('T')[0];
        break;
      default:
        // Default to current week
        const defaultWeek = getCurrentWeekDates();
        startDate = defaultWeek.startDate;
        endDate = defaultWeek.endDate;
    }
    
    setDateRange({ startDate, endDate });
  };
  
  return (
    <div className="min-h-screen bg-gray-100 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:flex md:items-center md:justify-between mb-8">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Admin Dashboard
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Overview of employee hours, check-ins, and flagged entries
            </p>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <Link to="/admin/reports" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              View Detailed Reports
            </Link>
          </div>
        </div>
        
        {/* Date Range Selector */}
        <div className="bg-white shadow rounded-lg p-4 mb-6">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Time Period
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {dateRange.startDate} to {dateRange.endDate}
              </p>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-4">
              <select
                id="date-range"
                name="date-range"
                onChange={handleDateRangeChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="this-week">This Week</option>
                <option value="last-week">Last Week</option>
                <option value="this-month">This Month</option>
              </select>
            </div>
          </div>
        </div>
        
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500"></div>
          </div>
        ) : (
          <>
            {/* Stats Overview */}
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-6">
              {/* Total Hours */}
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total Hours
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {weeklyData?.totalHours || 0}
                  </dd>
                </div>
              </div>
              
              {/* Total Shifts */}
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Total Shifts
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {weeklyData?.totalShifts || 0}
                  </dd>
                </div>
              </div>
              
              {/* Active Employees */}
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Currently Active
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {activeShifts.length}
                  </dd>
                </div>
              </div>
              
              {/* Flagged Entries */}
              <div className="bg-white overflow-hidden shadow rounded-lg">
                <div className="px-4 py-5 sm:p-6">
                  <dt className="text-sm font-medium text-gray-500 truncate">
                    Flagged Entries
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-gray-900">
                    {flaggedEntries.length}
                  </dd>
                </div>
              </div>
            </div>
            
            {/* Charts and Tables Section */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
              {/* Hours by Day Chart */}
              <div className="bg-white shadow rounded-lg p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                  Hours by Day
                </h3>
                <div className="h-64">
                  {weeklyData && <HoursChart data={weeklyData.byDay} />}
                </div>
              </div>
              
              {/* Hours by Department Chart */}
              <div className="bg-white shadow rounded-lg p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                  Hours by Department
                </h3>
                <div className="h-64">
                  {weeklyData && <HoursChart data={weeklyData.byDepartmentArray} />}
                </div>
              </div>
            </div>
            
            {/* Top Employees Table */}
            <div className="bg-white shadow rounded-lg mb-6">
              <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Employee Hours
                </h3>
                <Link to="/admin/reports" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                  View All
                </Link>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Employee
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Department
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Role
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Hours
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Shifts
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {weeklyData?.byEmployee.map((employee) => {
                      const emp = employees.find(e => e.id === employee.id);
                      return (
                        <tr key={employee.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {employee.name}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{emp?.department || 'N/A'}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{emp?.role || 'N/A'}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{employee.hours}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{employee.shifts}</div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            
            {/* Active Shifts Section */}
            <div className="bg-white shadow rounded-lg mb-6">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Currently Active Shifts ({activeShifts.length})
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Employees currently clocked in
                </p>
              </div>
              {activeShifts.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Employee
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Clock In Time
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Location
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Duration
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {activeShifts.map((shift) => {
                        const clockInTime = new Date(shift.clockInTime);
                        const now = new Date();
                        const durationMs = now - clockInTime;
                        return (
                          <tr key={shift.id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {shift.employeeName}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {shift.role}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">
                                {clockInTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                              </div>
                              <div className="text-sm text-gray-500">
                                {clockInTime.toLocaleDateString()}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{shift.clockInLocation}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{formatDuration(durationMs)}</div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="px-4 py-5 sm:px-6 text-center text-gray-500">
                  No employees are currently clocked in.
                </div>
              )}
            </div>
            
            {/* Flagged Entries Section */}
            <div className="bg-white shadow rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Flagged Time Entries ({flaggedEntries.length})
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Time entries that require review
                </p>
              </div>
              {flaggedEntries.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Employee
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Duration
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Flags
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {flaggedEntries.map(({ shift, flags }) => (
                        <tr key={shift.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">
                              {shift.employeeName}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {new Date(shift.date).toLocaleDateString()}
                            </div>
                            <div className="text-sm text-gray-500">
                              {new Date(shift.clockInTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - 
                              {new Date(shift.clockOutTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">{shift.duration}</div>
                          </td>
                          <td className="px-6 py-4">
                            <ul className="text-sm text-red-600 list-disc list-inside">
                              {flags.reasons.map((reason, index) => (
                                <li key={index}>{reason}</li>
                              ))}
                            </ul>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                            <a href="#" className="text-indigo-600 hover:text-indigo-900 mr-3">Review</a>
                            <a href="#" className="text-green-600 hover:text-green-900">Approve</a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="px-4 py-5 sm:px-6 text-center text-gray-500">
                  No flagged time entries found.
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;